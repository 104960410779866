body.tox-fullscreen .tox.tox-tinymce.tox-fullscreen {
  z-index: 99999;
}

.tox.tox-silver-sink.tox-tinymce-aux {
  z-index: 99999;
}

/* to hide the freemium notfication */
.tox-notifications-container{
	display: none;
}
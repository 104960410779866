// this is necessary to overrule some of the chakra styles.
// kinda nasty but it works and its not a big deal.
.privacyPolicy {
  h2 {
    font-size: 1.2rem;
    font-weight: bold;
    margin-bottom: 1rem;
  }

  ul {
    padding-left: 20px;

    li {
      margin-bottom: 1rem;
    }
  }

  p {
    margin-bottom: 1rem;
  }

  a {
    &:hover {
      text-decoration: underline;
    }
  }
}
